@import url("./reset.css");



@tailwind base;
@tailwind components;
@tailwind utilities;




:root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



* {
  padding: 0;
  margin: 0;
  border: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}


/* html {
  height: 100%;
  width: 100%;
  position: relative;
}

body,
#root {
  height: 100%;
  width: 100%;
  position: relative;
} */

.css-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.h-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

a {
  text-decoration: none;
}